.fullPage{
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: #f9ed13;
}
.fixedMobileSize{
    width: 1024px;
    height: 80vh;
    max-width: 445px;
    background-color: #f9ed13;
}
.fixedMobileSizeForGame{
    min-height: 100vh;
    max-width: 445px;
    background-color: #f9ed13;
}
@keyframes glow {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    50% {
        opacity: 0.7;
        transform: scale(1.05);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
.welcome {
    margin-top: -70px;
    float: left;
    width: 100%;
}
.welcomeButton {
    float: left;
    letter-spacing: 5px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    width: 60%;
    margin: 0% 0% 10% 20%;
    padding: 5px 0px 5px 20px;
    border: 3px solid black;
    background-color: #ff37d3;
    color: white;
    cursor: pointer;
    border-radius: 40px;
    transition: background-color 0.3s ease;
    text-shadow: 0 0 6px black;
}
.welcomeButton:hover {
    background-color: #d900a9;
}
.userDetailsBlock {
    margin-top: -20px;
    float: left;
    width: 100%;
}

.userDetailsContainer {
    position: relative;
    width: 100%;
}

.userDetails {
    width: 100%;
}

.userDetailsBtn {
    float: left;
    letter-spacing: 5px;
    text-align: center;
    font-weight: 800;
    font-size: 20px;
    width: 60%;
    margin: -15% 0% 10% 20%;
    padding: 5px 0px 5px 20px;
    border: 3px solid black;
    background-color: #ff37d3;
    color: white;
    cursor: pointer;
    border-radius: 40px;
    transition: background-color 0.3s ease;
    text-shadow: 0 0 6px black;
}

.userDetailsBtn:hover {
    background-color: #d900a9;
}

.userData {
    float: left;
    position: absolute;
    top: 40%;
    left: 15%;
    right: 14%;
    text-align: center;
    color: white;
}

.userNameInput,
.phoneNumberInput {
    width: 100%;
    padding: 15px;
    margin-bottom: 10px;
    border: 2px solid #fff;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
}

.userNameInput::placeholder,
.phoneNumberInput::placeholder {
    font-size: 20px;
    color: white;
}


.sun {
    float: left;
    width: 40%;
    margin: 9% 0 0 7%;
    animation: glow 3s ease-in-out infinite alternate;
}

@keyframes sway {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(4deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.aththa {
    float: right;
    width: 40%;
    margin: 33% 0 0 7%;
    animation: sway 3s ease-in-out infinite alternate;
}

.lanuwa{
    float: left;
    width: 100%;
    margin: 15% 0 0 0;
}

.kale{
    float: left;
}

.kale1{
    margin: -4% 3% 3% 3%;
    animation: sway 5s ease-in-out infinite alternate;
    animation-delay: 0s;
    width: 26%;
}
.kale2{
    margin: -6% 3% 3% 3%;
    animation: sway 5s ease-in-out infinite alternate;
    animation-delay: 2s;
    width: 26%;
}
.kale3{
    margin: -9% 3% 3% 3%;
    animation: sway 5s ease-in-out infinite alternate;
    animation-delay: 3s;
    width: 26%;
}

.kadunaKale1{
    margin: -4% -21% 0% -17%;
    animation: sway 5s ease-in-out infinite alternate;
    animation-delay: 0s;
    width: 68%;
}
.kadunaKale2{
    margin: -5% -21% 0% -17%;
    animation: sway 5s ease-in-out infinite alternate;
    animation-delay: 0s;
    width: 68%;
}
.kadunaKale3{
    margin: -8% -21% 0% -17%;
    animation: sway 5s ease-in-out infinite alternate;
    animation-delay: 0s;
    width: 68%;
}